<template>
  <div>
    <filter-slot
      :filter="arrayFilters"
      :filter-principal="filterPrincipal"
      :total-rows="total_data"
      :paginate="paginate"
      :start-page="start_page"
      :to-page="to_page"
      :send-multiple-sms="false"
      @reload="$refs.refProductRequest.refresh()"
    >
      <template #table>
        <b-table
          ref="refProductRequest"
          class="position-relative"
          :items="myProvider"
          :fields="myFields"
          primary-key="id"
          table-class="text-nowrap"
          responsive
          show-empty
          hover
          no-border-collapse
          sticky-header="65vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :filter="search_input"
          thead-class="text-center"
          tbody-class="text-center"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(request_code)="data">
              <div>
                #000{{ data.item.id }}
              </div>
            </template>
          <template #cell(name_module)="data">
            <div>
              {{ data.item.module }}
            </div>
          </template>

          <template #cell(status)="data">
            <div class="d-flex justify-content-center">
              <b-badge
                :class="'bg-light-' + statusColor(data.item.status)"
                pill
              >
                {{ data.item.status }}
              </b-badge>
              <div
                v-if="
                  data.item.status == 'DELIVERED' &&
                  data.item.pending_to_be_assigned == 1
                "
              >
                <feather-icon
                  icon="AlertCircleIcon"
                  size="18"
                  class="text-danger ml-1"
                  v-b-tooltip.hover.top="'Product not assigned yet'"
                />
              </div>
            </div>
          </template>

          <template #cell(date_delivery)="data">
            <template v-if="data.item.date_delivery == null">
              No delivery date
            </template>
            <template v-else>
              {{ data.item.date_delivery | myGlobal }}
            </template>
          </template>

          <template #cell(request_by)="data">
            {{ data.item.created_by }}
          </template>

          <template #cell(created_at)="data">
            {{ data.item.created_at | myGlobalDay }}
          </template>

          <template #cell(actions)="data">
            <div>
              <feather-icon
                title="See request details"
                v-b-tooltip.hover.v-primary
                icon="InfoIcon"
                size="20"
                class="cursor-pointer text-primary"
                @click="openModalviewlist(data.item)"
              />
              <feather-icon
                title="Send to delivered"
                v-b-tooltip.hover.v-success
                icon="SendIcon"
                size="20"
                class="cursor-pointer ml-1 text-success"
                v-if="status == 'in-process' && currentUser.modul_id == 19"
                @click="sendRequest(data.item)"
              />
              <!-- boton para anular -->
              <feather-icon
                v-if="annullCondition()"
                v-b-tooltip.hover.v-danger
                title="Anull request"
                icon="TrashIcon"
                size="20"
                class="cursor-pointer ml-1 text-danger"
                @click="requestDeleted(data.item, 'annul')"
              />
              <!-- boton para rechazar -->
              <feather-icon
                v-if="status == 'pending' && currentUser.modul_id == 19"
                v-b-tooltip.hover.v-danger
                title="Anull request"
                icon="XIcon"
                size="20"
                class="cursor-pointer ml-1 text-danger"
                @click="requestDeleted(data.item, 'reject')"
              />
              <feather-icon
                v-b-tooltip.hover.v-info
                title="See request tracking"
                icon="ListIcon"
                size="20"
                class="cursor-pointer ml-1 text-info"
                @click="openRequestTrackingModal(data.item)"
              />
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>

    <modal-request-detail
      v-if="modalRequestDetails"
      :dataList="dataList"
      :typeRequest="status"
      @close="closeModalRequest()"
      @refreshTable="refresh()"
    ></modal-request-detail>

    <request-tracking-modal
      v-if="requestTrackingModalOn"
      :request-id="requestId"
      @close="closeRequestTrackingModal"
    />
  </div>
</template>

<script>
import fields from "@/views/commons/components/request/components/fields.data";
import filters from "@/views/commons/components/request/components/filters.data";
import RequestService from "@/views/commons/components/request/services/request.service";
import ModalRequestDetail from "@/views/commons/components/request/components/modals/ModalRequestDetail.vue";
import RequestTrackingModal from "@/views/commons/components/request/components/modals/RequestTrackingModal.vue";

import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    "modal-request-detail": ModalRequestDetail,
    RequestTrackingModal,
  },
  props: {
    status, //status in router
  },
  data() {
    return {
      arrayColumns: fields,
      arrayFilters: filters,

      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },

      total_data: 0,
      paginate: {
        currentPage: 1,
        perPage: 100,
      },

      sortBy: "created_at",
      start_page: 0,
      to_page: 0,
      isBusy: false,
      sortDesc: true,
      search_input: "",

      modalRequestDetails: false,
      dataList: null,
      requestTrackingModalOn: false,
      requestId: null,
      moduleFilter: false,
    };
  },
  created(){
    this.getModules();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    statusNum() {
      return this.status === "pending"
        ? 1
        : this.status === "in-process"
        ? 2
        : this.status === "delivered"
        ? 3
        : 4;
    },
    myFields(){
      this.arrayColumns[3].visible = this.status == 'in-process';
      return this.arrayColumns.filter(column => column.visible)
    },
    myFilters(){
      if(this.currentUser.modul_id != 19){
        return this.arrayFilters.filter(item => item.label != 'Program');
      }
      return this.arrayFilters;
    }
  },
  methods: {
    async myProvider() {
      try {
        const params = {
          name_text: this.filterPrincipal.model,
          from: this.arrayFilters[0].model,
          to: this.arrayFilters[1].model,
          status: this.statusNum,
          moduleId: this.sendModuleId(),
          moduleFilter: this.moduleFilter,
          order: "created_at",
          orderby: "desc",
          perpage: this.paginate.perPage,
          page: this.paginate.currentPage,
        };

        const response = await RequestService.getRequest(params);
        let { data, from, to, current_page, per_page, last_page, total } =
          response.data;
        const items = data;
        this.start_page = from;
        this.to_page = to;
        this.paginate.currentPage = current_page;
        this.paginate.perPage = per_page;
        this.next_page = this.start_page + 1;
        this.end_page = last_page;
        this.total_data = total;

        return items || [];
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    openModalviewlist(item) {
      this.dataList = item;
      this.modalRequestDetails = true;
    },
    refresh() {
      this.$refs.refProductRequest.refresh();
    },
    sendModuleId(){
      if(this.currentUser.modul_id == 19){
        if(this.arrayFilters[2].model == null){
          return this.currentUser.modul_id;
        }else{
          this.moduleFilter = true;
          return this.arrayFilters[2].model;
        }
      }else{
        return this.currentUser.modul_id;
      }
    },
    statusColor(status) {
      if (status == "PENDING") {
        return "secondary";
      } else if (status == "IN PROCESS") {
        return "primary";
      } else if (status == "DELIVERED") {
        return "success";
      } else if (status == "REJECTED") {
        return "danger";
      } else if (status == "ANULLED") {
        return "danger";
      }
    },
    sendRequest(item) {
      const params = {
        status: 3,
        id: item.id,
        user: this.currentUser.user_id,
        created_by: item.created_by_id,
      };

      this.$swal({
        title: "Are you sure to change the status to delivered?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          this.addPreloader();
          await RequestService.sendRequestStatus(params);
          this.A_SET_COUNTERS_DELIVERED({ moduleId: this.currentUser.modul_id });
          this.showToast(
            "success",
            "top-right",
            "Congratulations",
            "CheckIcon",
            "You've successfully"
          );
          this.removePreloader();
          this.$refs.refProductRequest.refresh();
        }
      });
    },
    ...mapActions({
      A_SET_COUNTERS_DELIVERED: "RequestStore/A_SET_COUNTERS_DELIVERED",
    }),
    requestDeleted(item, type) {
      let typeService = type == "reject" ? "requestDeleted" : "requestAnnulled";
      const params = {
        id: item.id,
        user: this.currentUser.user_id,
        created_by: item.created_by_id,
      };

      this.$swal({
        title: `Are you sure you want to ${type} the request?`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          this.addPreloader();
          await RequestService[typeService](params);
          this.showToast(
            "success",
            "top-right",
            "Congratulations",
            "CheckIcon",
            "You've successfully"
          );
          this.removePreloader();
          this.$refs.refProductRequest.refresh();
        }
      });
    },
    annullCondition() {
      if (this.currentUser.modul_id == 19) {
        return this.status == "in-process" ? true : false;
      } else {
        return this.status == "pending" || this.status == "in-process"
          ? true
          : false;
      }
    },

    async getModules(){
      try {
        const data = await RequestService.getModules();
        filters[2].options = data;
      } catch (error) {
        throw error
      }
    },
    refreshTable(){
      this.moduleFilter = false;
      this.$refs.refProductRequest.refresh()
    },
    closeModalRequest(){
      this.modalRequestDetails = false;
    },
    openRequestTrackingModal(item) {
      this.requestId = item.id;
      this.requestTrackingModalOn = true;
    },
    closeRequestTrackingModal() {
      this.requestId = null;
      this.requestTrackingModalOn = false;
    },
  },
};
</script>
