export default [
    {
        key: "id",
        label: "",
        class: "text-left",
        sortable: false,
        visible: true,
    },
    {
        key: "sku_code",
        label: "SKU",
        visible: true,
    },
    {
        key: "name_item",
        label: "Product",
        class: "text-left",
        sortable: false,
        visible: true,
    },
    {
        key: "specifications_name",
        label: "SPECIFICATIONS",
        class: "text-center",
        sortable: false,
        visible: true,
    },
    {
        key: "availability_status",
        label: "AVAILABILITY",
        class: "text-left",
        sortable: false,
        visible: true,
    }
];