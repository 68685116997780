export default [
    {
        key: "sku_code",
        label: "SKU CODE",
        class: "text-left",
        sortable: false,
        visible: false,
      },
      {
        key: "product",
        label: "PRODUCT",
        class: "text-left",
        sortable: false,
        visible: true,
      },
      {
        key: "quantity",
        label: "QUANTITY",
        class: "text-center",
        sortable: false,
        visible: true,
      },
      {
        key: "asigned_to",
        label: "ASIGNED TO",
        class: "text-center",
        sortable: false,
        visible: false,
      },
      {
        key: "status",
        label: "STATUS",
        class: "text-center",
        sortable: false,
        visible: true,
      },
      // {
      //   key: "date_delivery",
      //   label: "DATE DELIVERY",
      //   class: "text-center",
      //   sortable: false,
      //   visible: true,
      // },
];