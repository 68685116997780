<template>
  <b-modal
    ref="inventory_detailed_by_item"
    v-model="isActive"
    :size="'xmd'"
    title="REQUEST TRACKING"
    hide-footer
    scrollable
    @hidden="hidden"
  >
    <div>
      <b-table
        slot="table"
        ref="grid_inventory_detailed_by_item"
        no-provider-filtering
        :items="myProvider"
        :fields="fields"
        primary-key="id"
        table-class="text-nowrap"
        show-empty
        sticky-header="70vh"
        :busy.sync="isBusy"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :current-page="paginate.currentPage"
        :per-page="paginate.perPage"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(status)="data">
          <b-badge
            :variant="statusColor(data.item.status)"
            pill
          >
            {{ data.item.status }}
          </b-badge>
        </template>
        <template #cell(created_at)="data">
          <span>{{ data.item.created_at | myGlobalWithHour }}</span>
        </template>
      </b-table>
    </div>
  </b-modal>
</template>

<script>
import Fields from '@/views/commons/components/request/components/fields-request-tracking.data';
import RequestService from '@/views/commons/components/request/services/request.service';

export default {
  props: {
    requestId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      editItemDetailsOpnened: false,
      fields: Fields,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      isActive: false,
      isBusy: false,
      itemDetailId: null,
      totalRows: 0,
      startPage: null,
      toPage: null,
      nextPage: null,
      sortBy: '',
      sortDesc: true,
      editItemDetailsOn: false,
    };
  },
  async created() {
    this.isActive = true;
  },
  methods: {
    hidden() {
      this.isActive = false;
      this.$emit('close');
    },
    async myProvider() {
      try {
        const data = await RequestService.getRequestTracking({
          requestId: this.requestId,
        });
        return data;
      } catch (error) {
        this.showErrorSwal(error);
        console.error(error);
      }
    },
    statusColor(status) {
      if (status == 'PENDING') {
        return 'secondary';
      } if (status == 'IN PROCESS') {
        return 'primary';
      } if (status == 'DELIVERED') {
        return 'success';
      } if (status == 'REJECTED') {
        return 'danger';
      } if (status == 'ANULLED') {
        return 'danger';
      }
    },
  },
};
</script>
