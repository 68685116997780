export default [
  {
    key: 'request_code',
    label: 'REQUEST CODE',
    class: 'text-center',
    sortable: false,
    visible: true,
  },
  {
    key: 'status',
    label: 'Status',
    class: 'text-center',
    sortable: false,
    visible: true,
  },
  {
    key: 'status',
    label: 'STATUS',
    class: 'text-center',
    sortable: false,
    visible: true,
    thStyle: { width: '180px' },
  },
  {
    key: 'created_at',
    label: 'CREATED AT',
    class: 'text-center',
    sortable: false,
    visible: true,
  },
  {
    key: 'created_by',
    label: 'Created by',
    class: 'text-center',
    sortable: false,
    visible: true,
  },
];
