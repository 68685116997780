export default [
    {
        key: "request_code",
        label: "REQUEST CODE",
        class: "text-center",
        sortable: false,
        visible: true,
    },
    {
        key: "name_module",
        label: "DPTO",
        class: "text-center",
        sortable: false,
        visible: true,
    },
    {
        key: "status",
        label: "STATUS",
        class: "text-center",
        sortable: false,
        visible: true,
        thStyle: { width: "180px" },
    },
    {
        key: "date_delivery",
        label: "DELIVERY DATE",
        class: "text-center",
        sortable: false,
        visible: false,
    },
    {
        key: "request_by",
        label: "REQUESTED BY",
        class: "text-center",
        sortable: false,
        visible: true,
    },
    {
        key: "created_at",
        label: "CREATED",
        class: "text-center",
        sortable: false,
        visible: true,
    },
    {
        key: "actions",
        label: "ACTIONS",
        class: "text-center",
        sortable: false,
        visible: true,
      },
  ];