<template>
  <div>
    <b-modal
      modal
      :title="'REQUEST DETAIL'"
      v-model="ifModal"
      size="xmd"
      modal-class="modal-primary"
      hide-footer
      no-close-on-backdrop
      scrollable
      body-class="mb-2"
      @hidden="closeModal(null)"
      title-tag="h3"
    >
      <div>
        <ValidationObserver ref="form">
          <b-row>
            <b-col md="6" class="mb-1">
              <b-input-group prepend="DPTO:" class="mr-sm-2 mb-sm-0">
                <b-form-input v-model="dataList.module" disabled></b-form-input>
              </b-input-group>
            </b-col>
            <b-col md="6" class="mb-1">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <b-input-group prepend="Date Delivery:" class="mr-sm-2 mb-sm-0">
                  <b-form-datepicker
                    id="example-datepicker"
                    v-model="dateDelivery"
                    :disabled="
                      !(
                        currentUser.modul_id == 19 &&
                        (typeRequest == 'pending' ||
                          typeRequest == 'in-process')
                      )
                    "
                    :state="errors[0] ? false : null"
                  ></b-form-datepicker>
                </b-input-group>
              </ValidationProvider>
            </b-col>
            <b-col>
              <b-input-group class="mr-sm-2 mb-sm-0">
                <b-form-textarea v-model="dataList.note" disabled />
              </b-input-group>
            </b-col>
            <b-col lg="12" md="12" class="mb-2 mt-1">
              <div>
                <b-table
                  ref="refProductRequestDetail"
                  class="position-relative"
                  :items="myProvider"
                  :fields="myFields"
                  primary-key="id"
                  table-class="text-nowrap"
                  responsive="lg"
                  show-empty
                  hover
                  no-border-collapse
                  :busy.sync="isBusy"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :current-page="paginate.currentPage"
                  :per-page="paginate.perPage"
                  :filter="search_input"
                  thead-class="text-center"
                  tbody-class="text-center"
                >
                  <template #table-busy>
                    <div class="text-center text-primary my-2">
                      <b-spinner class="align-middle mr-1" />
                      <strong>Loading ...</strong>
                    </div>
                  </template>

                  <template #cell(sku_code)="data">
                    <span class="text-left">
                      {{ data.item.sku_code }}
                    </span>
                  </template>

                  <template #cell(product)="data">
                    <span class="text-left d-block">
                      {{ data.item.product }}
                      <span
                        v-if="
                          (data.item.stock > 0 && data.item.is_detailed == 1) ||
                          (data.item.status && data.item.is_detailed == 1)
                        "
                        size="sm"
                        :class="
                          data.item.isActive == true
                            ? 'icon_detail bg-light-danger'
                            : 'icon_detail_down'
                        "
                        :style="validateRow(data) ? 'background:#f00' : ''"
                        @click="toggleDetails(data)"
                      >
                        <feather-icon
                          :icon="
                            data.item.isActive == true
                              ? 'ChevronUpIcon'
                              : 'ChevronDownIcon'
                          "
                          size="20"
                          :color="validateRow(data) ? 'white' : 'gray'"
                        />
                      </span>
                      <br />
                      <b-badge variant="info">CAT:</b-badge>
                      <span class="font-12 ml-1">{{
                        data.item.subcategoria
                      }}</span
                      ><br />
                      <b-badge
                        v-if="typeRequest == 'pending'"
                        :class="data.item.stock === 0 ? 'bg-light-danger' : (data.item.stock > 0 && data.item.stock < data.item.minimum_stock ? 'bg-light-warning' : 'bg-light-success')"
                        >Stock {{data.item.stock}}</b-badge
                      >
                    </span>
                  </template>

                  <template #cell(quantity)="data">
                    <b-form-input
                      v-model="data.item.quantity"
                      placeholder="quantity"
                      class="quantity mx-auto text-center"
                      type="number"
                      @input="changeQuantity(data)"
                      :disabled="disabledQuantity(data)"
                      :value="0"
                    ></b-form-input>
                  </template>
                  <!-- aqui -->
                  <template #cell(status)="data">
                    <div v-if="typeRequest == 'pending'">
                      <b-form-checkbox
                        v-model="data.item.status"
                        name="check-button"
                        :disabled="disabledStatus(data)"
                        @change="changeStatusDetail(data)"
                        switch
                      ></b-form-checkbox>
                    </div>
                    <div v-else class="d-flex justify-content-center">
                      <div
                        :class="
                          data.item.status ? 'text-success' : 'text-danger'
                        "
                      >
                        {{ statusItem(data.item.status) }}
                      </div>
                    </div>
                  </template>

                  <template #cell(date_delivery)="data">
                    <div>
                      {{ data.item.date_delivery | myGlobalDay }}
                    </div>
                  </template>

                  <template #cell(asigned_to)="data">
                    <div>
                      <b-form-group>
                        <template
                          v-if="
                            currentUser.modul_id != 19 ||
                            currentUser.user_id == dataList.created_by_id
                          "
                        >
                          <b-form-select
                            :options="users"
                            v-model="data.item.asigned_to"
                            value-field="user_id"
                            text-field="username"
                            class="select-icon-none"
                            @change="saveAsigned(data)"
                            :disabled="disabledAsigned(data)"
                          >
                            <template #first>
                              <b-form-select-option :value="null" disabled
                                >Select an user</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </template>
                        <template v-else>
                          <b-form-input
                            v-model="data.item.asigned_name"
                            disabled
                          ></b-form-input>
                        </template>
                      </b-form-group>
                    </div>
                  </template>

                  <template #row-details="data">
                    <div>
                      <b-table
                        :items="data.item.item_detail"
                        :fields="myFields2"
                        sticky-header="30vh"
                        :busy.sync="isBusy"
                      >
                        <template #cell(id)="rows">
                          <b-form-radio
                            :value="rows.item.id"
                            v-model="data.item.item_detail_id"
                            :name="'data' + data.item.id"
                            :checked="data.item.item_detail_id == rows.item.id"
                            @change="pushItemDetail(data, rows)"
                            :disabled="data.item.radio == rows.item.id"
                          >
                            <span
                              class="bg-light-danger"
                              v-if="data.item.radio == rows.item.id"
                              >Not available</span
                            >
                          </b-form-radio>
                        </template>

                        <template #cell(sku_code)="data">
                          <span class="text-center">
                            {{ data.item.sku_code }}
                          </span>
                        </template>

                        <template #cell(name_item)="data">
                          {{ data.item.name_item }}
                          {{ data.item.name_brand }}
                          <br />
                          <b-badge variant="primary" class="condition ml-0"
                            >Condition</b-badge
                          >
                          <span class="condition">{{
                            data.item.condition_status
                          }}</span>
                        </template>

                        <template #cell(specifications_name)="data">
                          <span v-if="isEmptyObject(data.item.specifications)"
                            >No specifications defined</span
                          >
                          <div v-else>
                            <div
                              class="mr-4"
                              v-for="(value, key) in data.item.specifications"
                              :key="key"
                            >
                              <ul class="list-unstyled">
                                <ol class="no-padding-list">
                                  {{
                                    key.toUpperCase()
                                  }}:
                                  {{
                                    value
                                  }}
                                </ol>
                              </ul>
                            </div>
                          </div>
                        </template>

                        <template #cell(availability_status)="data">
                          <b-badge class="bg-light-success" pill>{{
                            data.item.availability_status
                          }}</b-badge>
                        </template>
                      </b-table>
                    </div>
                  </template>
                </b-table>
              </div>
            </b-col>
            <b-col md="12">
              <b-row>
                <b-col md="6" lg="6" xl="6">
                  <b-form-checkbox
                    v-model="checkboxAll"
                    class="ml-2"
                    switch
                    :disabled="
                      currentUser.modul_id != 19 ||
                      (currentUser.modul_id == 19 && typeRequest != 'pending')
                    "
                    @change="allCheckboxChange(true)"
                    >Status all</b-form-checkbox
                  >
                </b-col>
                <b-col md="6" lg="6" xl="6" class="text-right">
                  <b-button
                    variant="danger"
                    class="mr-1"
                    v-if="
                      currentUser.modul_id == 19 && typeRequest == 'pending'
                    "
                    @click="rejectRequest(dataList)"
                  >
                    <feather-icon icon="SaveIcon" size="15" />
                    REJECT
                  </b-button>

                  <b-button
                    :disabled="disabledSave"
                    variant="primary"
                    @click="saveDetail"
                    v-if="
                      currentUser.modul_id == 19 && typeRequest == 'pending'
                    "
                  >
                    <!-- (currentUser.modul_id != 19 && typeRequest == 'delivered')-->
                    <feather-icon icon="SaveIcon" size="15" />
                    APPROVE
                  </b-button>
                  <b-button
                    variant="primary"
                    @click="saveDate()"
                    v-if="disabledButton"
                  >
                    <feather-icon icon="SaveIcon" size="15" />
                    SAVE
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </ValidationObserver>
      </div>
    </b-modal>
  </div>
</template>
    
<script>
import fields from "@/views/commons/components/request/components/fields.modal.data";
import fields_item_detail from "@/views/commons/components/request/components/fields.modal.item.detail";
import RequestService from "@/views/commons/components/request/services/request.service";
// Import Data
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  props: {
    dataList: {
      type: Object,
      default: () => {},
    },
    typeRequest: String,
  },
  data() {
    return {
      items: [],
      ifModal: true,
      listProducts: [],
      arrayColumns: fields,
      isBusy: false,
      sortBy: "created_at",
      sortDesc: true,
      paginate: {
        currentPage: 1,
        perPage: 100,
      },
      search_input: "",
      statusRequest: false,
      dateDelivery: null,

      arrayColumnsDetail: fields_item_detail,
      selectItems: [],
      ItemsRequestDetail: [],
      users: [],
      checkboxAll: false,
      disabledSave: true,
      disabledButton: false,
    };
  },
  watch: {
    dateDelivery: function (value) {
      if (
        !(this.currentUser.modul_id == 19 && this.typeRequest == "in-process")
      ) {
        this.disabledButton = false;
      } else {
        this.disabledButton = true;
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    convertirBool(valor) {
      return valor === 1;
    },
    myFields: function () {
      this.arrayColumns[3].visible = this.typeRequest == "delivered";
      return this.arrayColumns.filter((field) => field.visible);
    },
    myFields2: function () {
      this.arrayColumnsDetail[0].visible = this.typeRequest == "pending";
      this.arrayColumnsDetail[1].visible = true;
      return this.arrayColumnsDetail.filter((field) => field.visible);
    },
    existsRequestsWithoutUserAssinged() {
      return this.items.some(
        (item) => item.request_status == "APPROVED" && item.asigned_to == null
      );
    },
  },
  methods: {
    async myProvider() {
      const params = {
        id: this.dataList.id,
      };
      const response = await RequestService.requestDetail(params);
      response.map((item) => {
        item.request_status = item.status;
        item.status == "APPROVED"
          ? (item.status = true)
          : (item.status = false);
        item.asigned_name = item.asigned_name ?? "No asigned";
        item.is_asigned = item.asigned_to != null ? true : false;
      });
      this.items = response;
      return this.items || [];
    },
    ...mapActions({
      A_SET_COUNTERS_DELIVERED: "RequestStore/A_SET_COUNTERS_DELIVERED",
    }),
    closeModal(num) {
      this.$emit("close", num);
    },
    getSpecificationsWithNoEmptyValues(obj) {
      /*
        Remove the empty values from a object, for instance:
        For: {"ram": "", "storage": "", "processor": "Intel"} the output expected is: { "processor": "Intel" }
        For: {"ram": "", "storage": "", "processor": ""} the output expected is: { }
      */
      const myObj = JSON.parse(obj);
      const result = {};
      for (const key in myObj) {
        if (myObj.hasOwnProperty(key) && myObj[key] != "") {
          result[key] = myObj[key];
        }
      }

      return result;
    },
    async toggleDetails(row) {
      if (this.typeRequest != "rejected") {
        try {
          row.item.isActive == true
            ? (row.item.isActive = false)
            : (row.item.isActive = true);
          let item_detail_id =
            this.typeRequest == "in-process" || this.typeRequest == "delivered"
              ? row.item.item_detail_id
              : null;
          this.addPreloader();
          const params = {
            id_item: row.item.item_id,
            availability_status: "AVAILABLE",
            condition_status: "NEW,USED,REPAIRED",
            item_detail_id: item_detail_id,
          };
          const response = await RequestService.getItemDetail(params);
          row.item.item_detail = response || [];

          for (const item of row.item.item_detail) {
            const updatedSpecifications =
              this.getSpecificationsWithNoEmptyValues(item.specifications);
            item.specifications = updatedSpecifications;
          }

          this.removePreloader();
          row.toggleDetails();
        } catch (error) {
          console.log(error);
        }
      }
    },
    pushItemDetail(data, rows) {
      this.selectItems.forEach((element, index) => {
        if (element.itemRequestDetailId == data.item.id) {
          this.selectItems.splice(index, 1);
        }
      });
      let resultado = this.selectItems.find(
        (field) => field.itemDetailId == rows.item.id
      );
      if (resultado != null) {
        data.item.radio = rows.item.id;
        data.item.item_detail_id = null;
      } else {
        data.item.radio = 0;
        this.selectItems.forEach((element, index) => {
          if (element.itemRequestDetailId == data.item.id) {
            this.selectItems.splice(index, 1);
          }
        });

        this.selectItems.push({
          itemRequestDetailId: data.item.id,
          itemDetailId: rows.item.id,
          quantity: 1,
        });
      }
      this.validateGrid(); //validate grid missing to complete
    },
    detailItems(data) {
      if (this.dataList.status == "DELIVERED") {
      } else {
        return data.item.item_detail;
      }
    },
    changeStatusDetail(data) {
      if (data.item.is_detailed == 0) {
        this.selectItems.forEach((element, index) => {
          if (element.itemRequestDetailId == data.item.id) {
            this.selectItems.splice(index, 1);
          }
        });
        if (data.item.status) {
          this.selectItems.push({
            itemRequestDetailId: data.item.id,
            itemDetailId: null,
            quantity: data.item.quantity,
          });
        }
      }
      this.checkboxAll = this.items.every((item) => item.status === true);
      /*this.ItemsRequestDetail.forEach((element, index) => {
          if(element.request_id == row.item.id) {
            this.ItemsRequestDetail.splice(index, 1);
          }
        });
        if(row.item.status){
          this.ItemsRequestDetail.push({
            request_id:row.item.id,
            request_status:row.item.status ? "APPROVED" : "PENDING",
            quantity:parseInt(row.item.quantity)
          });
        }*/

      this.allCheckboxChange(false); //change to false button status all
      this.validateGrid(); //validate grid missing to complete
    },
    changeQuantity(data) {
      if (data.item.status) {
        let resultado = this.selectItems.find(
          (field) => field.itemRequestDetailId == data.item.id
        );
        resultado.quantity = parseInt(data.item.quantity);
      }
    },
    validateRow(data) {
      return (
        data.item.stock > 0 &&
        data.item.is_detailed == 1 &&
        data.item.status &&
        data.item.item_detail_id == null
      );
    },
    async saveDetail() {
      const validation = await this.$refs.form.validate();
      if (validation) {
        this.selectItems.forEach((element, index) => {
          let resultado = this.items.find(
            (field) => field.id == element.itemRequestDetailId
          );
          if (resultado.status == false) {
            this.selectItems.splice(index, 1);
          }
        });

        try {
          const { isConfirmed } = await this.showConfirmSwal();
          if (!isConfirmed) return;
          this.addPreloader();
          const params = {
            item_request_id: this.dataList.id,
            list: JSON.stringify(this.selectItems).toString(),
            date_delivery: this.dateDelivery,
            created_by: this.dataList.created_by_id,
            user_id: this.currentUser.user_id,
          };
          const { status } = await RequestService.saveDetail(params);
          if (status == 200) {
            this.A_SET_COUNTERS_DELIVERED({
              moduleId: this.currentUser.modul_id,
            });
            this.showSuccessSwal();
          }
          this.removePreloader();
          this.$refs.refProductRequestDetail.refresh();
          this.closeModal();
          this.$parent.$refs["refProductRequest"].refresh();
        } catch (error) {
          console.log(error);
        } finally {
          this.removePreloader();
        }
      }
    },
    async saveDate() {
      const validation = await this.$refs.form.validate();
      if (validation) {
        try {
          const { isConfirmed } = await this.showConfirmSwal();
          if (!isConfirmed) return;
          this.addPreloader();
          const params = {
            date: this.dateDelivery,
            requestId: this.dataList.id,
          };
          const { success } = await RequestService.saveDate(params);
          if (success) {
            this.A_SET_COUNTERS_DELIVERED({
              moduleId: this.currentUser.modul_id,
            });
            this.showSuccessSwal();
          }
          this.removePreloader();
          this.$refs.refProductRequestDetail.refresh();
          this.closeModal();
          this.$parent.$refs["refProductRequest"].refresh();
        } catch (error) {
          console.log(error);
        } finally {
          this.removePreloader();
        }
      }
    },
    isEmptyObject(obj) {
      return Object.keys(obj).length === 0;
    },
    rejectRequest(item) {
      const params = {
        id: item.id,
        user: this.currentUser.user_id,
        created_by: this.dataList.created_by_id,
      };

      this.$swal({
        title: "Are you sure you want to reject the request?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          this.addPreloader();
          await RequestService.requestDeleted(params);
          this.A_SET_COUNTERS_DELIVERED({
            moduleId: this.currentUser.modul_id,
          });
          this.showToast(
            "success",
            "top-right",
            "Congratulations",
            "CheckIcon",
            "You've successfully"
          );
          this.removePreloader();
          this.$emit("refreshTable");
          this.$emit("close");
        }
      });
    },
    statusItem(status) {
      return status ? "Approved" : "Rejected";
    },
    async getUsersByModule() {
      if (this.typeRequest == "delivered") {
        const params = {
          moduleId: this.currentUser.modul_id,
        };
        try {
          const data = await RequestService.getUsersByModule(params);
          this.users = data;
        } catch (error) {
          console.log(error);
        }
      }
    },
    allCheckboxChange(status) {
      if (this.items.length > 0) {
        let numx = 0;
        this.items.forEach((element, index) => {
          if (element.stock > 0 && status) {
            element.status = this.checkboxAll;
          }
          //change to false button status all
          if (status == false && element.status == false) {
            numx++;
          }
        });
        if (this.items.length == numx) {
          this.checkboxAll = false;
        }
      }
      this.validateGrid();
    },
    validateGrid() {
      // Disabled or enabled the save button
      let numz = 0;
      let counter = 0;
      this.items.forEach((element, index) => {
        if (element.status == false) {
          counter++;
        }
        //validate with detail
        if (
          element.is_detailed == 1 &&
          element.stock > 0 &&
          element.status == true &&
          element.item_detail_id == null
        ) {
          numz++;
        }
      });

      if (this.items.length == counter) {
        this.disabledSave = true;
      } else {
        this.disabledSave = numz > 0 ? true : false;
      }
    },
    disabledStatus(data) {
      if (this.currentUser.modul_id != 19) {
        return true;
      } else {
        if (this.typeRequest != "pending" || data.item.stock <= 0) {
          return true;
        }
      }
      return false;
    },
    disabledQuantity(data) {
      if (this.typeRequest == "pending") {
        if (this.currentUser.modul_id != 19) {
          return true;
        } else {
          return data.item.stock == 0 || data.item.is_detailed == 1
            ? true
            : false;
        }
      }
      return true;
    },
    disabledAsigned(data) {
      if (this.currentUser.user_id == this.dataList.created_by_id) {
        if (
          (data.item.status && data.item.is_asigned) ||
          data.item.item_detail_id == null
        ) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    },
    async saveAsigned(data) {
      try {
        const params = {
          item_request_detail_id: data.item.id,
          asigned_to: data.item.asigned_to,
          user_id: this.currentUser.user_id,
        };
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.value) {
            this.addPreloader();
            const response = await RequestService.saveAsigned(params);
            this.A_SET_COUNTERS_DELIVERED({
              moduleId: this.currentUser.modul_id,
            });
            this.showToast(
              "success",
              "top-right",
              "Congratulations",
              "CheckIcon",
              "You've successfully"
            );
            this.removePreloader();
            this.$refs.refProductRequestDetail.refresh();
            if (!this.existsRequestsWithoutUserAssinged) {
              this.$emit("refreshTable");
            }
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.dateDelivery = this.dataList.date_delivery;
    this.getUsersByModule();
  },
};
</script>
<style>
.font-12 {
  font-size: 12px;
}
.condition {
  font-size: 10px;
  margin-left: 5px;
}
.quantity {
  width: 60px;
}
.icon_detail {
  border: solid 1px #847e7e;
  border-radius: 11px;
  padding: 1px 0 3px 0;
}
.icon_detail_down {
  border: solid 1px #847e7e;
  border-radius: 11px;
  padding: 2px 0 2px 0;
}
.stock_item {
  margin: 6px 0 0 0px;
}
</style>